import { ReactElement } from 'react';

import { Project } from './Projects';

export default function ProjectDOM({ project }: { project: Project}): ReactElement {
    return (
        <div className="project">
            <div className="project-title">{project.title}</div>
            <div className="project-description">{project.description}</div>
            <div className="project-footer">
                <div className="project-languages">
                    {project.languages.map((language) => {
                        return (
                            <div className="project-language">{language}</div>
                        )
                    })}
                </div>
                <div className="project-links">
                    {project.hasVisitLink ? <a target="_blank" href={project.visitLink} className="project-visit-link">Visit</a> : ""}
                    {project.hasGithubLink ? <a target="_blank" href={project.githubLink} className="project-github-link"><i className="fa-brands fa-github"></i></a> : ""}
                </div>
            </div>
        </div>
    )
}