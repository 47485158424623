import { ReactElement } from 'react';

import JavaScriptLogo from '../logos/JavaScript-logo.png';
import TypeScriptLogo from '../logos/Typescript_logo_2020.svg.png';
import ReactLogo from '../logos/React-icon.svg.png';
import PythonLogo from '../logos/Python-logo-notext.svg.png';
import JavaLogo from '../logos/Java-Logo.png';
import FlaskLogo from '../logos/Flask-logo.png';
import MySQLLogo from '../logos/mysql-logo.svg';
import GitHubLogo from '../logos/github-logo.png';

export default function TechStack(): ReactElement {
    type Logo = {name: string, src: string};
    const languages:Logo[] = [
        {
            name: "JavaScript",
            src: JavaScriptLogo,
        },
        {
            name: "TypeScript",
            src: TypeScriptLogo,
        },
        {
            name: 'React',
            src: ReactLogo,
        },
        {
            name: 'Python',
            src: PythonLogo,
        },
        {
            name: 'Java',
            src: JavaLogo,
        },
        {
            name: 'Flask',
            src: FlaskLogo,
        },
        {
            name: 'MySQL',
            src: MySQLLogo,
        },
        {
            name: 'GitHub',
            src: GitHubLogo
        }
    ];

    return (
        <div className="tech-stack-area">
            <div className="tech-stack-text">Tech Stack</div>
            <div className="tech-stack-languages">
                {languages.map((language:{name:string, src:string}) => {
                    return (<div className="language-div" title={language.name}>
                        <img src={language.src} className={`language-logo ${language.name}-logo`} />
                    </div>);
                })}
            </div>
            {/* <div className="tech-stack-and-more-text">and more...</div> */}
        </div>
    )
};