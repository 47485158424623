import { ReactElement } from "react";
import HeroImage from "../hero-image.png";

export default function Header(): ReactElement {
    return(
        <div className="header">
            <div className="header-top">
                <div className="header-image-area">
                    <img className="hero-image" src={HeroImage} />
                </div>
                <div className="header-name-area">
                    Erdem Yılmaz
                </div>
                <div className="header-job-area">Software Developer</div>
            </div>
            <div className="header-description-area">
                <div className="header-about-text">
                <strong>Hi there!</strong> I’m a passionate <strong>software developer</strong> who loves working with <strong>JavaScript</strong>, <strong>TypeScript</strong>, and <strong>React</strong> on <strong>Frontend</strong>, <strong>Java</strong> and <strong>Python</strong> on Backend. I’m currently diving into <strong>CNC machine programming</strong> and <strong>machine learning</strong>, always eager to learn and innovate. With an engineering background from <strong>Izmir Institute of Technology</strong> (1/4), I’m all about creating cool and impactful tech solutions.
                </div>
                <div className="header-links-area">
                    <a className="link" target="_blank" href="https://github.com/erdemylmaz">
                        <i className="fa-brands fa-github"></i>
                    </a>
                    <a className="link" target="_blank" href="https://instagram.com/erdmylmazx">
                        <i className="fa-brands fa-instagram"></i>
                    </a>
                    <a className="link" target="_blank" href="https://www.linkedin.com/in/erdem-y%C4%B1lmaz-b497921a8/">
                        <i className="fa-brands fa-linkedin"></i>
                    </a>
                </div>
            </div>
        </div>
    ) 
}
